<template>
  <b-overlay :show="isFetchingDashboard">
    <section id="dashboard-ecommerce">
      <b-row class="mb-3">
        <b-col
          v-if="isOwner"
          cols="12"
          md="3"
        >
          <MasterSelectInput 
            :value="masterId"
            hide-label
            @update="onMasterIdChange"
          />
        </b-col>
        <b-col
          v-if="isMaster || isOwner"
          cols="12"
          md="3"
        >
          <agent-select-input
            :value="agentId"
            :master-id="masterId"
            hide-label
            @update="onAgentIdChange"
          />
        </b-col>
        <b-col class="text-right">
          <b-row>
            <b-col>
              <day-picker
                :initial-value="selectedDate"
                @update="(date) => selectedDate = date"
              />
            </b-col>
            <b-col
              cols="4"
              class="text-right"
            >
              <b-button
                variant="relief-primary"
                block
                @click="fetchData"
              >
                ดึงผลสรุปยอด
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col>
          <dashboard-statistics
            title="เดือนนี้"
            :data="totalStats"
          />
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col>
          <dashboard-statistics
            title="วันนี้"
            :data="todayStats"
          />
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col>
          <dashboard-transactions
            title="เดือนนี้"
            :data="totalOverviews"
          />
        </b-col>
        <b-col>
          <dashboard-transactions
            title="วันนี้"
            :data="todayOverviews"
          />
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapState, mapGetters } from 'vuex'

import DashboardStatistics from './dashboard/Statistics.vue'
import DashboardTransactions from './dashboard/Transactions.vue'

export default {
  components: {
    DashboardStatistics,
    DashboardTransactions,
  },
  data() {
    return {
      data: {},
      agentId: '',
      masterId: '',
      selectedDate: '',
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dashboard', 'isOwner', 'isMaster']),
    ...mapState({
      isFetchingDashboard: (state) => state.user.isFetchingDashboard,
    }),
    totalDeposit() {
      const { total } = this.dashboard;
      return (+total.deposit + +total.manual_deposit)
    },
    todayDeposit() {
      const { today } = this.dashboard;
      return (+today.deposit + +today.manual_deposit)
    },
    totalWithdraw() {
      const { total } = this.dashboard;
      return (+total.withdraw + +total.manual_withdraw)
    },
    todayWithdraw() {
      const { today } = this.dashboard;
      return (+today.withdraw + +today.manual_withdraw)
    },
    totalStats() {
      const { total } = this.dashboard;
     
      return [
        {
          color: 'light-primary',
          customClass: 'mb-3',
          icon: 'UsersIcon',
          subtitle: 'สมาชิกเดือนนี้',
          title: total.register_count,
          unit: `/ ${this.$t('fields.person')}`,
        },
        {
          color: 'light-success',
          customClass: 'mb-3',
          icon: 'DollarSignIcon',
          subtitle: 'รับเครดิตฟรีเดือนนี้',
          title: total.free_credit_count,
          unit: `/ ${this.$t('fields.credit')}`,
        },
        {
          color: 'light-danger',
          customClass: 'mb-3',
          icon: 'DollarSignIcon',
          subtitle: 'จำนวนเครดิตฟรีที่เสียเดือนนี้',
          title: total.free_credit_spent,
          unit: `/ ${this.$t('fields.credit')}`,
        },
        {
          color: 'light-info',
          customClass: 'mb-3',
          icon: 'PlusCircleIcon',
          subtitle: 'ยอดฝากรวมเดือนนี้',
          title: this.totalDeposit,
          unit: `/ ${this.$t('fields.person')}`,
          sub1: `ฝากออโต้ ${this.$options.filters.money(total.deposit)}`,
          sub2: `ฝากมือ ${this.$options.filters.money(total.manual_deposit)}`,
        },
        {
          color: 'light-warning',
          customClass: 'mb-3',
          icon: 'MinusCircleIcon',
          subtitle: 'ยอดถอนรวมเดือนนี้',
          title: this.totalWithdraw,
          unit: `/ ${this.$t('fields.credit')}`,
          sub1: `ถอนออโต้ ${this.$options.filters.money(total.withdraw)}`,
          sub2: `ถอนมือ ${this.$options.filters.money(total.manual_withdraw)}`,
        },
        {
          color: 'light-secondary',
          customClass: 'mb-3',
          icon: 'ActivityIcon',
          subtitle: 'สรุปรายได้เดือนนี้',
          title: (this.totalDeposit - this.totalWithdraw),
          unit: `/ ${this.$t('fields.credit')}`,
        },
      ]
    },
    todayStats() {
      const { today } = this.dashboard;
      return [
        {
          color: 'light-primary',
          customClass: 'mb-3',
          icon: 'UsersIcon',
          subtitle: 'สมาชิกวันนี้',
          title: today.register_count,
          unit: `/ ${this.$t('fields.person')}`,
        },
        {
          color: 'light-success',
          customClass: 'mb-3',
          icon: 'DollarSignIcon',
          subtitle: 'รับเครดิตฟรีวันนี้',
          title: today.free_credit_count,
          unit: `/ ${this.$t('fields.credit')}`,
        },
        {
          color: 'light-danger',
          customClass: 'mb-3',
          icon: 'DollarSignIcon',
          subtitle: 'จำนวนเครดิตฟรีที่เสียวันนี้',
          title: today.free_credit_spent,
          unit: `/ ${this.$t('fields.credit')}`,
        },
        {
          color: 'light-info',
          customClass: 'mb-3',
          icon: 'PlusCircleIcon',
          subtitle: 'ยอดฝากรวมวันนี้',
          title: this.todayDeposit,
          unit: `/ ${this.$t('fields.person')}`,
          sub1: `ฝากออโต้ ${this.$options.filters.money(today.deposit)}`,
          sub2: `ฝากมือ ${this.$options.filters.money(today.manual_deposit)}`,
        },
        {
          color: 'light-warning',
          customClass: 'mb-3',
          icon: 'MinusCircleIcon',
          subtitle: 'ยอดถอนรวมวันนี้',
          title: this.todayWithdraw,
          unit: `/ ${this.$t('fields.credit')}`,
          sub1: `ถอนออโต้ ${this.$options.filters.money(today.withdraw)}`,
          sub2: `ถอนมือ ${this.$options.filters.money(today.manual_withdraw)}`,
        },
        {
          color: 'light-secondary',
          customClass: 'mb-3',
          icon: 'ActivityIcon',
          subtitle: 'สรุปรายได้วันนี้',
          title: (this.todayDeposit - this.todayWithdraw),
          unit: `/ ${this.$t('fields.credit')}`,
        },
      ]
    },
    totalOverviews() {
      const { total } = this.dashboard;
      return [
        {
          avatar: 'PocketIcon',
          avatarVariant: 'light-primary',
          value: `${this.$options.filters.money(total.first_deposit_count)}
          /${this.$options.filters.money(total.first_deposit_amount)}`,
          title: 'เติมเงินครั้งแรกเดือนนี้',
          unit: '/คน/เครดิต',
        },
        {
          avatar: 'CalendarIcon',
          avatarVariant: 'light-info',
          value: `${this.$options.filters.money(total.first_deposit_in_day_count)}
          /${this.$options.filters.money(total.first_deposit_in_day_amount)}`,
          title: 'ฝากเงินครั้งแรกของวันเดือนนี้',
          unit: '/คน/เครดิต',
        },
        {
          avatar: 'GiftIcon',
          avatarVariant: 'light-success',
          value: `${this.$options.filters.money(total.all_time_count)}
          /${this.$options.filters.money(total.all_time_amount)}`,
          title: 'รับโบนัสทุกยอดฝากเดือนนี้',
          unit: '/คน/เครดิต',
        },
        {
          avatar: 'ClockIcon',
          avatarVariant: 'light-warning',
          value: `${this.$options.filters.money(total.special_time_count)}
          /${this.$options.filters.money(total.special_time_amount)}`,
          title: 'ฝากเป็นเวลาเดือนนี้',
          unit: '/คน/เครดิต',
        },
      ]
    },
    todayOverviews() {
      const { today } = this.dashboard;
      return [
        {
          avatar: 'PocketIcon',
          avatarVariant: 'light-primary',
          value: `${this.$options.filters.money(today.first_deposit_count)}
          /${this.$options.filters.money(today.first_deposit_amount)}`,
          title: 'เติมเงินครั้งแรกวันนี้',
          unit: '/คน/เครดิต',
        },
        {
          avatar: 'CalendarIcon',
          avatarVariant: 'light-info',
          value: `${this.$options.filters.money(today.first_deposit_in_day_count)}
          /${this.$options.filters.money(today.first_deposit_in_day_amount)}`,
          title: 'ฝากเงินครั้งแรกของวันวันนี้',
          unit: '/คน/เครดิต',
        },
        {
          avatar: 'GiftIcon',
          avatarVariant: 'light-success',
          value: `${this.$options.filters.money(today.all_time_count)}
          /${this.$options.filters.money(today.all_time_amount)}`,
          title: 'รับโบนัสทุกยอดฝากวันนี้',
          unit: '/คน/เครดิต',
        },
        {
          avatar: 'ClockIcon',
          avatarVariant: 'light-warning',
          value: `${this.$options.filters.money(today.special_time_count)}
          /${this.$options.filters.money(today.special_time_amount)}`,
          title: 'ฝากเป็นเวลาวันนี้',
          unit: '/คน/เครดิต',
        },
      ]
    },
  },
  methods: {
    ...mapActions(['fetchDashboard']),
    fetchData() {
      this.fetchDashboard({
        agentId: this.agentId, 
        masterId: this.masterId, 
        date: this.selectedDate? dayjs(this.selectedDate).format('YYYY-MM-DD'): '',
      })
    },
    onAgentIdChange(agentId) {
      this.agentId = agentId
    },
    onMasterIdChange(masterId) {
      this.masterId = masterId
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
