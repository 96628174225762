<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, key) in data"
          :key="key"
          :class="item.customClass"
          md="4"
          sm="6"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title | currency }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
                <small
                  v-if="item.unit"
                  class="font-size-12 font-weight-normal"
                >
                  {{ item.unit }}
                </small>
              </b-card-text>
              <b-card-text
                v-if="item.sub1"
                class="font-small-3 mb-0"
              >
                {{ item.sub1 }}
              </b-card-text>
              <b-card-text
                v-if="item.sub2"
                class="font-small-3 mb-0"
              >
                {{ item.sub2 }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
